/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import HeaderSearch from './HeaderSearch'
import './Header.css'
import axios from 'axios'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import { Button, Popover, ListItem, Divider, SwipeableDrawer, MenuItem } from '@material-ui/core'
import { handleRedirectInternal } from '../../../Product/common/components'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useMediaQuery } from 'react-responsive'
import { Logo } from '../../../utils/index'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import BecomeSeller from '../../../utils/CommonFunctionality/BecomeSeller'
import CustomDialog from '../../../Product/components/organisms/Dialog'
import CartIcon from '../../../utils/CommonFunctionality/Cart/CartIcon'
import ProductContext from '../../../Product/context/product/productContext'
import Timer from '../../../Product/common/timer'
import url from 'socket.io-client-latest/lib/url'
import StripeCardContext from '../../../Product/context/stripe/card/cardContext'
import DirectStripeContext from '../../../Product/context/directStripe/directStripeContext'
import Headernavmenu from '../../../utils/CommonFunctionality/HeaderNavigaton/headernavigation'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.css'
import { EffectCoverflow, Navigation, Autoplay, Pagination } from 'swiper'
import UserContext from '../../../Product/context/user/userContext'
import pluginContext from '../../../Product/context/plugin/pluginContext'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import GoogleMaps from '../../../Component/MapInput'
// import ReactPixel from '../../../utils/facebookpixel'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="bottom" ref={ref} {...props} />
})

const Header = (props) => {
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)
    const contactPhone = global?.storeDetails?.phone
    const facebookLink = global?.storeDetails?.facebook
    const twitterLink = global?.storeDetails?.twitter
    const googleLink = global?.storeDetails?.google
    const instagramLink = global?.storeDetails?.instagram

    const location = useLocation()
    const [filterValues, setFilterValues] = useState([])
    const history = useHistory()
    const [anchormenu, setanchormenu] = useState(null)
    const [catAnchormenu, setCatAnchormenu] = useState(null)
    const [dealAnchormenu, setDealAnchormenu] = useState(null)
    const [auctionMenu, setAuctionMenu] = useState(null)
    const [open, setOpen] = useState(false)
    const {
        request_become_seller,
        responseStatus: pluginstatus,
        clearResponse: clearpluginresponse,
    } = useContext(pluginContext)
    const { user, isAuthenticated, logout, userCount, loadUser } = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const userContext = useContext(UserContext)
    const { get_all_card_details } = useContext(StripeCardContext)
    const { usercards } = useContext(DirectStripeContext)
    const [cards, setCards] = useState([{}])
    const {
        search_allproducts,
        getAllSearchProducts,
        search_allauctions,
        getAllSearchAuctions,
        search_allauctionproducts,
        getLotList,
        setState: setLocalState,
        productLocalState,
        setState: setProductState,
    } = productContext

    const { getLocation, countryValues, termsCheckUpdate, responseStatus } = userContext

    const [viewAuction, setViewAuction] = useState([])
    const [viewCategories, setViewCategories] = useState([])
    const [termsDialog, setTermsDialog] = useState(false)
    const [termsCheck, setTermsCheck] = useState(false)
    const [auctionSearch, setAuctionSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        type: '',
    })
    const [sublotSearch, setSublotSearch] = useState({
        page: 1,
        sh_limit: '10',
        orderby: '2',
        searchbar: '',
        auc_type: '',
        type: 'active',
        auction_state:
            productLocalState.globalLocationFilter == 1
                ? []
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[1]?.trim()]
                : [productLocalState.globalLocation],
        auction_city:
            productLocalState.globalLocationFilter == 1
                ? [productLocalState.globalLocation]
                : productLocalState.globalLocationFilter == 2
                ? [productLocalState.globalLocation?.split(',')?.[0]?.trim()]
                : [],
    })

    const checkThemeClassName = document.body.className == 'liquidationTwo primeBids'
    const checkEmursTheme = document.body.className == 'liquidationTwo emursMck'
    const { setAlert } = useContext(AlertContext)
    const isDesktop = useMediaQuery({
        query: '(min-width: 991px)',
    })
    const [state, setState] = useState({
        top: false,
        right: false,
        left: false,
    })

    const [listingAnchor, setListingAnchor] = useState(null)
    const listingOpen = Boolean(listingAnchor)

    const [locationDialog, setLocationDialog] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const [currentLocation, setCurrentLocation] = useState('Houston')

    useEffect(() => {
        if (window.location.hostname === 'localhost') {
            axios.defaults.headers.common['domain'] = 'auction_preview.ecommerce.auction'
        }
    }, [])

    useEffect(() => {
        if (user) {
            setTermsDialog(user?.terms_check ? false : true)
        }
    }, [user])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'termsCheckUpdate') {
                if (responseStatus.status === 'success') {
                    loadUser()
                    setTermsDialog(false)
                }
                setBtnLoading(false)
            }
        }
    }, [responseStatus])
    useEffect(() => {
        setFilterValues(
            global?.allCategories
                ?.filter((val) => val.level === 0)
                ?.sort((a, b) => a.description.localeCompare(b.description)),
        )
    }, [global.allCategories])
    useEffect(() => {
        getAllSearchAuctions(auctionSearch, 'activeHeader')
    }, [auctionSearch])

    useEffect(() => {
        getAllSearchProducts(sublotSearch, 'activeHeader')
    }, [sublotSearch])

    useEffect(() => {
        if (search_allauctions.from === 'activeHeader') {
            setViewAuction(search_allauctions.results.length ? search_allauctions.results : [])
        }
    }, [search_allauctions])

    useEffect(() => {
        if (search_allproducts.from === 'activeHeader') {
            setViewCategories(
                search_allproducts.maincategory.length ? search_allproducts.maincategory : [],
            )
        }
    }, [search_allproducts])

    const handleMenu = (event) => {
        setanchormenu(event.currentTarget)
    }

    const handleMenuAuction = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setAuctionMenu(event.currentTarget)
    }
    const checkBeachThemeClassName = document.body.className == 'liquidationTwo beachLiquidation'
    const handleClose = (history, path) => {
        setanchormenu(null)
        setAuctionMenu(null)
        if (history && path && path !== 'backdropClick') {
            handleRedirectInternal(history, path)
        }
    }
    const handleCatClose = () => {
        setCatAnchormenu(null)
    }
    const handleCatMenu = (event) => {
        setCatAnchormenu(event.currentTarget)
    }
    const handleDealsClose = () => {
        setDealAnchormenu(null)
    }
    const handleDealsMenu = (event) => {
        setDealAnchormenu(event.currentTarget)
    }
    const handleListingClick = (event) => {
        if (!user.card_profileid) {
            setAlert('Kindly add account id to continue!', 'warning')
            return handleRedirectInternal(history, 'dashboard/profile')
        }
        setListingAnchor(event.currentTarget)
    }

    const handleListingClose = () => {
        setListingAnchor(null)
    }
    const toggleOpen = () => {
        setOpen(!open)
    }

    // const filterValues = [
    //     {
    //         title: 'Category',
    //         type: 'check',
    //         name: 'category',
    //         options: global.searchCategories.filter((main) => {
    //             return true
    //         }),
    //     },
    // ]

    const logoutUser = () => {
        logout()
        if (window.location.href.includes(global?.storeDetails?.id)) {
            window.location.href = `/${global?.storeDetails?.id}/login`
        } else {
            window.location.href = '/login'
        }
    }

    const toggleDrawer = (anchor, open) => (event) => {
        setState({ ...state, [anchor]: open })
    }

    const handleClick = (catId) => {
        handleRedirectInternal(history, `search/product-buyer-auction/${catId}`)
    }

    const handleSellClick = (path) => {
        if (isAuthenticated) {
            if (!user?.card_profileid) {
                setAlert('Kindly add account id to continue!', 'warning')
                return handleRedirectInternal(history, 'dashboard/profile')
            } else if (user && user.role == 1) {
                return handleRedirectInternal(history, path)
            } else {
                return handleRedirectInternal(history, 'becomeSeller')
            }
        } else {
            return handleRedirectInternal(history, 'becomeSeller')
        }
    }

    let newUrl = new URLSearchParams(location.search)

    useEffect(() => {
        if (get_all_card_details) {
            setCards(get_all_card_details.records)
        }
    }, [get_all_card_details])

    useEffect(() => {
        if (usercards?.length) {
            setCards(usercards)
        }
    }, [usercards])

    useEffect(() => {
        getLocation()
    }, [])

    const handleLocationDialog = () => {
        setLocationDialog(false)
    }
    const handleTermsDialog = () => {
        setTermsDialog(false)
    }

    const handleLocationChange = (locationValue) => {
        localStorage.setItem('globalLocation', locationValue)
        setProductState({
            ...productLocalState,
            globalLocation: locationValue,
        })
        setLocationDialog(false)
        if (productLocalState.disableBuynowLocations?.includes(locationValue)) {
            if (
                window.location.pathname.includes('cart') ||
                window.location.pathname.includes('/checkout/buynow')
            ) {
                // handleRedirectInternal(history, '')
                history.replace('/')
            }
        }
        if (window.location.pathname.includes('product-buyer-auction')) {
            // handleRedirectInternal(history, '')
            history.replace('/')
        }
    }

    useEffect(() => {
        if (productLocalState.disableBuynowLocations?.includes(productLocalState.globalLocation)) {
            if (
                window.location.pathname.includes('cart') ||
                window.location.pathname.includes('/checkout/buynow')
            ) {
                handleRedirectInternal(history, '')
            }
        }
    }, [window.location])

    // useEffect(() => {
    //     if (global?.storeConfigration?.fb_meta_pixel_id?.value) {
    //         ReactPixel.pageView()
    //     }
    // }, [window.location])

    const confirm_become_seller = () => {
        request_become_seller({
            user_id: user.id,
            seller_user_id: global?.storeDetails?.user_id,
            email: user.email,
        })
    }

    useEffect(() => {
        if (pluginstatus) {
            if (pluginstatus.from === 'request_become_seller_from') {
                if (pluginstatus.status === 'success') {
                    window.open(pluginstatus.message)
                }
            }
            clearpluginresponse()
        }
    }, [pluginstatus])

    const isBidDepotProject = document.body.classList[1] == 'BidAuctionDepot'
    const isWizardTheme = document.body.classList[1] == 'wizardTheme'

    useEffect(() => {
        if (checkEmursTheme) {
            setLocalState((prevState) => ({
                ...prevState,
                globalLocation: 'New York',
                globalLocations: ['New York'],
                globalLocationFilter: 2,
            }))
            localStorage.setItem('globalLocation', JSON.stringify('New York'))
        }
        if (localStorage.getItem('globalLocation') == 'California' || checkEmursTheme) {
            localStorage.setItem('globalLocation', 'New York')
        }
    }, [search_allproducts, sublotSearch])

    return (
        <>
            {isDesktop ? (
                /* DESKTOP NAVIGATION */
                <>
                    <header className="mainHeader noPrint">
                        <div className="customContainer d-flex align-items-center h-100">
                            <div className="headLt d-flex justify-content-start align-items-center">
                                <Logo className="headerLogo" />
                                {isWizardTheme && (
                                    <div className="lctnFltr primeLoc">
                                        <span className="material-icons lctnIconVw">
                                            location_on
                                        </span>
                                        <div>
                                            <Button
                                                className="fltrValue"
                                                onClick={() => setLocationDialog(true)}
                                            >
                                                {productLocalState.globalLocationFilter == 1
                                                    ? productLocalState.globalLocation
                                                          ?.split(',')?.[0]
                                                          ?.trim()
                                                    : productLocalState.globalLocation}
                                                <span className="material-icons">expand_more</span>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {(!isBidDepotProject || checkEmursTheme) && !isWizardTheme && (
                                    <>
                                        <Button
                                            aria-controls="categoriesDrop"
                                            aria-haspopup="true"
                                            className="categoriesButton"
                                            onClick={handleCatMenu}
                                        >
                                            Categories{' '}
                                            <span class="material-icons">expand_more</span>
                                        </Button>
                                        <Popover
                                            id={'categoriesDrop'}
                                            open={Boolean(catAnchormenu)}
                                            anchorEl={catAnchormenu}
                                            onClose={handleCatClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <ul className="categoriesDropMenu">
                                                {checkThemeClassName || !checkEmursTheme
                                                    ? viewCategories?.map((data, index) => (
                                                          <ListItem
                                                              button
                                                              onClick={() => {
                                                                  handleCatClose()
                                                                  handleRedirectInternal(
                                                                      history,
                                                                      'search?q=&z=&m=&view=0&cat=' +
                                                                          data.cat_id,
                                                                  )
                                                              }}
                                                          >
                                                              <div
                                                                  className="PmCategoryList-root"
                                                                  key={index}
                                                              >
                                                                  <h4 className="PmCategoryTitle m-0">
                                                                      {data.cat_name} (
                                                                      {data.catcount})
                                                                  </h4>
                                                              </div>
                                                          </ListItem>
                                                      ))
                                                    : filterValues?.map((data, index) => (
                                                          <ListItem button>
                                                              <div
                                                                  className="PmCategoryList-root"
                                                                  key={index}
                                                                  onClick={() =>
                                                                      handleRedirectInternal(
                                                                          history,
                                                                          'search?q=&z=&m=&view=0&cat=' +
                                                                              data.id,
                                                                      )
                                                                  }
                                                              >
                                                                  {checkEmursTheme ? null : (
                                                                      <picture className="PmCategoryImage-root">
                                                                          <img
                                                                              className="PmCategoryImage lazy"
                                                                              src={
                                                                                  data.image !=
                                                                                  'noimg'
                                                                                      ? process.env
                                                                                            .REACT_APP_PRODUCT_IMAGE_URL +
                                                                                        data.image
                                                                                      : process.env
                                                                                            .REACT_APP_AUCTION_IMAGE_URL +
                                                                                        global
                                                                                            ?.storeDetails
                                                                                            ?.logo
                                                                              }
                                                                              data-src="image.jpg"
                                                                              alt={data.description}
                                                                          />
                                                                      </picture>
                                                                  )}
                                                                  <h4 className="PmCategoryTitle m-0">
                                                                      {data.description}
                                                                  </h4>
                                                              </div>
                                                          </ListItem>
                                                      ))}
                                            </ul>
                                        </Popover>
                                        <Button
                                            aria-controls="dealsDrop"
                                            aria-haspopup="true"
                                            className="categoriesButton"
                                            onClick={handleDealsMenu}
                                        >
                                            Deals <span class="material-icons">expand_more</span>
                                        </Button>
                                        <Popover
                                            id={'dealsDrop'}
                                            open={Boolean(dealAnchormenu)}
                                            anchorEl={dealAnchormenu}
                                            onClose={handleDealsClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <ul className="dealsDropMenu">
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'search?q=&z=&m=&view=0&sort=15',
                                                        )
                                                    }
                                                >
                                                    <i className="material-icons-outlined">
                                                        keyboard_double_arrow_up
                                                    </i>{' '}
                                                    Todays Top Deals
                                                </ListItem>
                                                {/* <ListItem button>
                                            <i className="material-icons-outlined">
                                                keyboard_double_arrow_up
                                            </i>{' '}
                                            Current price low to high
                                        </ListItem>
                                        <ListItem button>
                                            <i className="material-icons-outlined">
                                                keyboard_double_arrow_down
                                            </i>{' '}
                                            Current price high to low
                                        </ListItem> */}
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'search?q=&z=&m=&view=0&sort=2',
                                                        )
                                                    }
                                                >
                                                    <i className="material-icons-outlined">
                                                        horizontal_split
                                                    </i>{' '}
                                                    Recently Listed
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'search?q=&z=&m=&view=0&sort=',
                                                        )
                                                    }
                                                >
                                                    <i className="material-icons-outlined">
                                                        hourglass_bottom
                                                    </i>{' '}
                                                    Deals Ending Soon
                                                </ListItem>
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'search?q=&z=&m=&view=0&sort=14',
                                                        )
                                                    }
                                                >
                                                    <i className="material-icons-outlined">
                                                        local_fire_department
                                                    </i>{' '}
                                                    Best Deals
                                                </ListItem>
                                                {checkEmursTheme ? null : (
                                                    <ListItem
                                                        button
                                                        onClick={() =>
                                                            handleRedirectInternal(
                                                                history,
                                                                'search?q=&z=&m=&view=0&sort=&type=2',
                                                            )
                                                        }
                                                    >
                                                        <i className="material-icons">pallet</i>
                                                        Pallets
                                                    </ListItem>
                                                )}
                                            </ul>
                                        </Popover>
                                    </>
                                )}
                            </div>
                            {/* {global?.storeConfigration?.disable_product_loc?.value != 1 ? (
                                <div className="lctnFltr">
                                    <span className="material-icons lctnIconVw">location_on</span>
                                    <div>
                                        <label>You’re Shopping</label>
                                        <Button
                                            className="fltrValue"
                                            onClick={() => setLocationDialog(true)}
                                        >
                                            {productLocalState.globalLocation}{' '}
                                            <span className="material-icons">expand_more</span>
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )} */}
                            <div className="headRt d-flex justify-content-between align-items-center w-100">
                                <ul className="hdrRtLt d-flex justify-content-start align-items-center">
                                    {user && user.role == 1 && (
                                        <>
                                            <li className="headerBtmDivider"></li>
                                            <li>
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenuAuction}
                                                >
                                                    Sell
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(auctionMenu)}
                                                    anchorEl={auctionMenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        {/* <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(history, `post-auction`)
                                                            }
                                                        >
                                                            Post Auction
                                                        </ListItem> */}

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'post-project?type=auction',
                                                                )
                                                            }
                                                        >
                                                            Post Single Lot
                                                        </ListItem>
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'post-project?type=buynow',
                                                                )
                                                            }
                                                        >
                                                            Post Buy Now
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    )}

                                    <Headernavmenu type="desk"></Headernavmenu>

                                    <li className="srchLstCntnr">
                                        {isWizardTheme ? (
                                            <Button
                                                aria-controls="categoriesDrop"
                                                aria-haspopup="true"
                                                className="categoriesButton"
                                                onClick={handleCatMenu}
                                            >
                                                All Categories{' '}
                                                <span class="material-icons">expand_more</span>
                                            </Button>
                                        ) : null}
                                        <Popover
                                            id={'categoriesDrop'}
                                            open={Boolean(catAnchormenu)}
                                            anchorEl={catAnchormenu}
                                            onClose={handleCatClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <ul className="categoriesDropMenu">
                                                {checkThemeClassName
                                                    ? viewCategories?.map((data, index) => (
                                                          <ListItem
                                                              button
                                                              onClick={() => {
                                                                  handleCatClose()
                                                                  handleRedirectInternal(
                                                                      history,
                                                                      'search?q=&z=&m=&view=0&cat=' +
                                                                          data.cat_id,
                                                                  )
                                                              }}
                                                          >
                                                              <div
                                                                  className="PmCategoryList-root"
                                                                  key={index}
                                                              >
                                                                  <h4 className="PmCategoryTitle m-0">
                                                                      {data.cat_name} (
                                                                      {data.catcount})
                                                                  </h4>
                                                              </div>
                                                          </ListItem>
                                                      ))
                                                    : filterValues?.map((data, index) => (
                                                          <ListItem button>
                                                              <div
                                                                  className="PmCategoryList-root"
                                                                  key={index}
                                                                  onClick={() =>
                                                                      handleRedirectInternal(
                                                                          history,
                                                                          'search?q=&z=&m=&view=0&cat=' +
                                                                              data.id,
                                                                      )
                                                                  }
                                                              >
                                                                  {checkEmursTheme ? null : (
                                                                      <picture className="PmCategoryImage-root">
                                                                          <img
                                                                              className="PmCategoryImage lazy"
                                                                              src={
                                                                                  data.image !=
                                                                                  'noimg'
                                                                                      ? process.env
                                                                                            .REACT_APP_PRODUCT_IMAGE_URL +
                                                                                        data.image
                                                                                      : process.env
                                                                                            .REACT_APP_AUCTION_IMAGE_URL +
                                                                                        global
                                                                                            ?.storeDetails
                                                                                            ?.logo
                                                                              }
                                                                              data-src="image.jpg"
                                                                              alt={data.description}
                                                                          />
                                                                      </picture>
                                                                  )}
                                                                  <h4 className="PmCategoryTitle m-0">
                                                                      {data.description}
                                                                  </h4>
                                                              </div>
                                                          </ListItem>
                                                      ))}
                                            </ul>
                                        </Popover>
                                        <HeaderSearch theme={isWizardTheme} />
                                    </li>
                                </ul>
                                {!isBidDepotProject && !isWizardTheme && (
                                    <div className="lctnFltr primeLoc">
                                        <span className="material-icons lctnIconVw">
                                            location_on
                                        </span>
                                        <div>
                                            <label>You’re Shopping</label>
                                            <Button
                                                className="fltrValue"
                                                onClick={() => setLocationDialog(true)}
                                            >
                                                {productLocalState.globalLocation}
                                                <span className="material-icons">expand_more</span>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                <ul className="d-flex justify-content-start align-items-center">
                                    {/* <li className="headerSellBtn adtnlNvBtn">
                                        <NavLink activeClassName="active" to="/auctions">
                                            <Button className="">
                                                <span className="material-icons mr-1">
                                                    manage_search
                                                </span>
                                                Auctions
                                            </Button>
                                        </NavLink>
                                    </li>

                                    <li className="headerSellBtn adtnlNvBtn">
                                        <NavLink activeClassName="active" to="/search">
                                            <Button className="">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="11.72"
                                                    height="8.99"
                                                    viewBox="0 0 11.72 8.99"
                                                >
                                                    <g
                                                        id="Group_3"
                                                        data-name="Group 3"
                                                        transform="translate(-12.269 -12.556)"
                                                    >
                                                        <path
                                                            id="Path_1"
                                                            data-name="Path 1"
                                                            d="M2.46,14.546c-.022-.009-.045-.017-.067-.026a.166.166,0,0,1-.122-.173c0-1.338.006-2.675,0-4.012a.342.342,0,0,1,.377-.352c.154.006.309,0,.475,0V9.9q0-1.153,0-2.305A1.226,1.226,0,0,1,3.136,7.4a.262.262,0,0,1,.248-.238,1.216,1.216,0,0,1,.142-.008L6.452,7.15H6.53V7.059q0-.644,0-1.287c0-.159.052-.211.21-.211H8.133l3.518,0a1.257,1.257,0,0,1,.215.02.233.233,0,0,1,.2.208.985.985,0,0,1,.014.164q0,2.343,0,4.686V10.9h1.484c.045,0,.09,0,.134.006a.278.278,0,0,1,.267.231c0,.019.012.037.018.055v3.174a.2.2,0,0,1-.229.171s-.007.006-.011.009ZM6.884,5.914V9.992H8.511c.206,0,.252.045.252.25,0,.162,0,.324,0,.487v.161h2.973V5.912H9.8c0,.035,0,.065,0,.094,0,.2,0,.394,0,.591a.261.261,0,0,1-.283.28c-.13,0-.259,0-.389,0a.272.272,0,0,1-.3-.31c0-.195,0-.389-.005-.584,0-.024,0-.047-.006-.072ZM2.631,14.185H8.41V10.347h-2.4c0,.05,0,.095,0,.139,0,.2,0,.4-.015.6a.208.208,0,0,1-.209.213c-.162.01-.324.015-.486.009a.25.25,0,0,1-.262-.268c0-.055,0-.11,0-.165q0-.265,0-.529h-2.4Zm9.054-2.926c0,.231,0,.452,0,.674a.254.254,0,0,1-.245.286,3.546,3.546,0,0,1-.508-.007.22.22,0,0,1-.208-.2,1.692,1.692,0,0,1-.013-.238c0-.171,0-.342,0-.513H8.784v2.922h4.838V11.259Zm-8.2-1.277H6.516V7.515H5.485c0,.227,0,.449,0,.67a.256.256,0,0,1-.268.292,4.054,4.054,0,0,1-.441,0,.235.235,0,0,1-.255-.254c-.009-.209-.005-.419-.007-.628,0-.026,0-.052,0-.077H3.481ZM9.176,5.91v.611h.269V5.91Zm2.16,5.346h-.264v.618h.264ZM4.87,7.512v.614h.262V7.512Zm.781,2.834H5.392v.615h.259Z"
                                                            transform="translate(10 7)"
                                                            fill="none"
                                                        />
                                                        <path
                                                            id="Path_2"
                                                            data-name="Path 2"
                                                            d="M29.094,7.628H31.02c0,.025.006.049.006.072,0,.194,0,.389.005.584a.272.272,0,0,0,.3.31c.13,0,.259,0,.389,0A.261.261,0,0,0,32,8.313c.005-.2,0-.394,0-.591,0-.03,0-.059,0-.094h1.94V12.6H30.973v-.161c0-.162,0-.324,0-.487,0-.2-.046-.249-.252-.25H29.094Z"
                                                            transform="translate(-12.21 5.285)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_3"
                                                            data-name="Path 3"
                                                            d="M4.376,37.232v-3.84h2.4q0,.265,0,.529c0,.055,0,.11,0,.165a.25.25,0,0,0,.262.268c.162.006.324,0,.486-.009a.208.208,0,0,0,.209-.213c.01-.2.01-.4.015-.6,0-.045,0-.089,0-.139h2.4v3.838Z"
                                                            transform="translate(8.255 -16.047)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_4"
                                                            data-name="Path 4"
                                                            d="M43.036,38.7h1.936v2.921H40.134V38.7h1.929c0,.17,0,.341,0,.513a1.692,1.692,0,0,0,.013.238.22.22,0,0,0,.208.2,3.545,3.545,0,0,0,.508.007.254.254,0,0,0,.245-.286C43.037,39.15,43.036,38.928,43.036,38.7Z"
                                                            transform="translate(-21.35 -20.439)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_5"
                                                            data-name="Path 5"
                                                            d="M9.312,19.407V16.94h1.033c0,.024,0,.05,0,.077,0,.21,0,.419.007.628a.235.235,0,0,0,.255.254,4.055,4.055,0,0,0,.441,0,.256.256,0,0,0,.268-.292c0-.222,0-.443,0-.67h1.032v2.467Z"
                                                            transform="translate(4.169 -2.426)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_6"
                                                            data-name="Path 6"
                                                            d="M42.413,7.614h.269v.611h-.269Z"
                                                            transform="translate(-23.237 5.296)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_7"
                                                            data-name="Path 7"
                                                            d="M53.692,38.687V39.3h-.264v-.618Z"
                                                            transform="translate(-32.357 -20.431)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_8"
                                                            data-name="Path 8"
                                                            d="M17.389,16.923h.262v.614h-.262Z"
                                                            transform="translate(-2.519 -2.412)"
                                                            fill="#fff"
                                                        />
                                                        <path
                                                            id="Path_9"
                                                            data-name="Path 9"
                                                            d="M20.682,33.4v.615h-.259V33.4Z"
                                                            transform="translate(-5.031 -16.05)"
                                                            fill="#fff"
                                                        />
                                                    </g>
                                                </svg>
                                                Products
                                            </Button>
                                        </NavLink>
                                    </li> */}

                                    {global.pluginConfiguration?.remote_seller?.enable &&
                                    isAuthenticated ? (
                                        <li>
                                            <ListItem
                                                button
                                                onClick={() => confirm_become_seller()}
                                            >
                                                Become Seller
                                            </ListItem>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {isAuthenticated ? (
                                        <>
                                            {isBidDepotProject ? (
                                                <>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/how_it_works"
                                                        >
                                                            How it Works
                                                        </NavLink>
                                                    </li>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/contactus"
                                                        >
                                                            Contact us
                                                        </NavLink>
                                                    </li>
                                                </>
                                            ) : null}
                                            <li>
                                                <Button
                                                    className=""
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'dashboard/favorites',
                                                        )
                                                    }
                                                >
                                                    <span className="material-icons-round mr-1">
                                                        star_border
                                                    </span>
                                                </Button>
                                            </li>
                                            {/* {user?.role == 0 ? (
                                                <li className="headerSellBtn">
                                                    <NavLink
                                                        activeClassName="active"
                                                        to="/auctions"
                                                    >
                                                        <Button className="">
                                                            <span className="material-icons-round mr-1">
                                                                star_border
                                                            </span>
                                                        </Button>
                                                    </NavLink>
                                                </li>
                                            ) : null} */}
                                            {productLocalState.disableBuynowLocations?.includes(
                                                productLocalState.globalLocation,
                                            ) ? null : (
                                                <li>
                                                    <CartIcon />
                                                </li>
                                            )}
                                            <li>
                                                <Button
                                                    className="headerIcons"
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleMenu}
                                                >
                                                    <span className="material-icons">
                                                        account_circle
                                                    </span>
                                                </Button>

                                                <Popover
                                                    id={'simple-menu'}
                                                    open={Boolean(anchormenu)}
                                                    anchorEl={anchormenu}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <ul className="headerDropMenu">
                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    `${
                                                                        user && user.role == 1
                                                                            ? 'dashboard/overview'
                                                                            : 'dashboard/mybids'
                                                                    }`,
                                                                )
                                                            }
                                                        >
                                                            Dashboard
                                                        </ListItem>

                                                        <ListItem
                                                            button
                                                            onClick={() =>
                                                                handleClose(
                                                                    history,
                                                                    'dashboard/profile',
                                                                )
                                                            }
                                                        >
                                                            Profile
                                                        </ListItem>
                                                        <ListItem button onClick={logoutUser}>
                                                            Logout
                                                        </ListItem>
                                                    </ul>
                                                </Popover>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            {isBidDepotProject ? (
                                                <>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/how_it_works"
                                                        >
                                                            How it Works
                                                        </NavLink>
                                                    </li>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/contactus"
                                                        >
                                                            Contact us
                                                        </NavLink>
                                                    </li>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/about"
                                                        >
                                                            About us
                                                        </NavLink>
                                                    </li>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/about"
                                                        >
                                                            Register
                                                        </NavLink>
                                                    </li>
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/login"
                                                            className="signInButton"
                                                        >
                                                            Sign in
                                                        </NavLink>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    {isWizardTheme ? (
                                                        <li className="headerSellBtn">
                                                            <NavLink
                                                                activeClassName="active"
                                                                to="/login"
                                                            >
                                                                <Button className="lgSgnBtn learnMore">
                                                                    Learn More
                                                                </Button>
                                                            </NavLink>
                                                        </li>
                                                    ) : null}
                                                    <li className="headerSellBtn">
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/login"
                                                        >
                                                            <Button className="lgSgnBtn">
                                                                Login / Signup
                                                            </Button>
                                                        </NavLink>
                                                    </li>
                                                </>
                                            )}
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                        {/* <div className="customContainer">
                        <div className="headBottomNav">
                           
                        </div>
                    </div> */}
                    </header>
                </>
            ) : (
                <>
                    {/* MOBILE / TAB NAVIGATION */}
                    <header className="row mainHeader mobHeader customContainer noPrint d-flex justify-content-between align-items-center">
                        <div className="headLt d-flex align-items-center col-6 pl-0">
                            <Button
                                className="respNavBtn mnLftNav"
                                onClick={toggleDrawer('left', true)}
                            >
                                <span className="material-icons">menu</span>
                            </Button>
                            <Logo className="headerLogo" />
                        </div>
                        {global?.storeConfigration?.disable_product_loc?.value != 1 ? (
                            <div className="headRt col-3 headerrightSecMob">
                                <div className="lctnFltr ml-0 justify-content-end">
                                    <span className="material-icons lctnIconVw">location_on</span>
                                    <div className="d-flex align-items-center">
                                        {/* <label className="mr-2">You’re Shopping</label> */}
                                        <Button
                                            className="fltrValue"
                                            onClick={() => setLocationDialog(true)}
                                        >
                                            {productLocalState.globalLocation}{' '}
                                            <span className="material-icons">expand_more</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        <div className="col-12 mt-2 px-0">
                            <div className="srchLstCntnr">
                                <HeaderSearch />
                            </div>
                        </div>
                        <div className="col-12 mt-2 px-0 categoriesInMob">
                            {(!isBidDepotProject || !checkEmursTheme) && (
                                <>
                                    <Button
                                        aria-controls="categoriesDrop"
                                        aria-haspopup="true"
                                        className="categoriesButton"
                                        onClick={handleCatMenu}
                                        disabled={viewCategories.length > 0 ? false : true}
                                    >
                                        Categories <span class="material-icons">expand_more</span>
                                    </Button>
                                    <Popover
                                        id={'categoriesDrop'}
                                        open={Boolean(catAnchormenu)}
                                        anchorEl={catAnchormenu}
                                        onClose={handleCatClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <ul className="categoriesDropMenu">
                                            {checkThemeClassName || !checkEmursTheme
                                                ? viewCategories?.map((data, index) => (
                                                      <ListItem
                                                          button
                                                          onClick={() => {
                                                              handleCatClose()
                                                              handleRedirectInternal(
                                                                  history,
                                                                  'search?q=&z=&m=&view=0&cat=' +
                                                                      data.cat_id,
                                                              )
                                                          }}
                                                      >
                                                          <div
                                                              className="PmCategoryList-root"
                                                              key={index}
                                                          >
                                                              <h4 className="PmCategoryTitle m-0">
                                                                  {data.cat_name} ({data.catcount})
                                                              </h4>
                                                          </div>
                                                      </ListItem>
                                                  ))
                                                : filterValues?.map((data, index) => (
                                                      <ListItem button>
                                                          <div
                                                              className="PmCategoryList-root"
                                                              key={index}
                                                              onClick={() =>
                                                                  handleRedirectInternal(
                                                                      history,
                                                                      'search?q=&z=&m=&view=0&cat=' +
                                                                          data.id,
                                                                  )
                                                              }
                                                          >
                                                              {checkEmursTheme ? null : (
                                                                  <picture className="PmCategoryImage-root">
                                                                      <img
                                                                          className="PmCategoryImage lazy"
                                                                          src={
                                                                              data.image != 'noimg'
                                                                                  ? process.env
                                                                                        .REACT_APP_PRODUCT_IMAGE_URL +
                                                                                    data.image
                                                                                  : process.env
                                                                                        .REACT_APP_AUCTION_IMAGE_URL +
                                                                                    global
                                                                                        ?.storeDetails
                                                                                        ?.logo
                                                                          }
                                                                          data-src="image.jpg"
                                                                          alt={data.description}
                                                                      />
                                                                  </picture>
                                                              )}
                                                              <h4 className="PmCategoryTitle m-0">
                                                                  {data.description}
                                                              </h4>
                                                          </div>
                                                      </ListItem>
                                                  ))}
                                        </ul>
                                    </Popover>
                                    <Button
                                        aria-controls="dealsDrop"
                                        aria-haspopup="true"
                                        className="categoriesButton"
                                        onClick={handleDealsMenu}
                                    >
                                        Deals <span class="material-icons">expand_more</span>
                                    </Button>
                                    <Popover
                                        id={'dealsDrop'}
                                        open={Boolean(dealAnchormenu)}
                                        anchorEl={dealAnchormenu}
                                        onClose={handleDealsClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <ul className="dealsDropMenu">
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        'search?q=&z=&m=&view=0&sort=15',
                                                    )
                                                }
                                            >
                                                <i className="material-icons-outlined">
                                                    keyboard_double_arrow_up
                                                </i>{' '}
                                                Todays Top Deals
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        'search?q=&z=&m=&view=0&sort=2',
                                                    )
                                                }
                                            >
                                                <i className="material-icons-outlined">
                                                    horizontal_split
                                                </i>{' '}
                                                Recently Listed
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        'search?q=&z=&m=&view=0&sort=',
                                                    )
                                                }
                                            >
                                                <i className="material-icons-outlined">
                                                    hourglass_bottom
                                                </i>{' '}
                                                Deals Ending Soon
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        'search?q=&z=&m=&view=0&sort=14',
                                                    )
                                                }
                                            >
                                                <i className="material-icons-outlined">
                                                    local_fire_department
                                                </i>{' '}
                                                Best Deals
                                            </ListItem>
                                            {checkEmursTheme ? null : (
                                                <ListItem
                                                    button
                                                    onClick={() =>
                                                        handleRedirectInternal(
                                                            history,
                                                            'search?q=&z=&m=&view=0&sort=&type=2',
                                                        )
                                                    }
                                                >
                                                    <i className="material-icons">pallet</i>
                                                    Pallets
                                                </ListItem>
                                            )}
                                        </ul>
                                    </Popover>
                                </>
                            )}
                        </div>
                    </header>
                    {isAuthenticated ? (
                        <div className={`mblNavFooter ${isAuthenticated ? '' : 'noAuth'}`}>
                            <div
                                className={`ftrNav headCart ${
                                    window.location.pathname.includes('dashboard/mybids')
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <Button
                                    className="respNavBtn crtIconBtn"
                                    onClick={() =>
                                        handleRedirectInternal(history, 'dashboard/mybids')
                                    }
                                >
                                    <span className="material-icons">gavel</span>
                                </Button>
                            </div>
                            <div
                                className={`ftrNav headCart ${
                                    window.location.pathname.includes('favorites') ? 'active' : ''
                                }`}
                            >
                                <Button
                                    className="respNavBtn crtIconBtn"
                                    onClick={() =>
                                        handleRedirectInternal(history, 'dashboard/favorites')
                                    }
                                >
                                    <span className="material-icons">favorite</span>
                                </Button>
                            </div>
                            {productLocalState.disableBuynowLocations?.includes(
                                productLocalState.globalLocation,
                            ) ? (
                                <div className="ftrNav"></div>
                            ) : (
                                <div
                                    className={`ftrNav headCart ${
                                        window.location.pathname.includes('cart') ? 'active' : ''
                                    }`}
                                >
                                    <Button
                                        className="respNavBtn crtIconBtn"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    >
                                        <CartIcon fillIcon />
                                    </Button>
                                </div>
                            )}

                            <div
                                className={`ftrNav headCart ${
                                    window.location.pathname.includes('preference') ? 'active' : ''
                                }`}
                            >
                                <Button
                                    className="respNavBtn crtIconBtn"
                                    onClick={() =>
                                        handleRedirectInternal(history, 'dashboard/preference')
                                    }
                                >
                                    <span className="material-icons">notifications</span>
                                </Button>
                            </div>
                            <div className="ftrNav headCart actnIcon">
                                <Button
                                    className="respNavBtn"
                                    onClick={toggleDrawer('right', true)}
                                >
                                    <span className="material-icons">account_circle</span>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <>
                            {location.pathname === '/login' ||
                            location.pathname === '/sign-up' ? null : (
                                <div className={`mblNavFooter ${isAuthenticated ? '' : 'noAuth'}`}>
                                    <PrimaryButton
                                        label="Login"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                    <SecondaryButton
                                        label="Signup"
                                        onClick={() => handleRedirectInternal(history, 'sign-up')}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    <React.Fragment>
                        <SwipeableDrawer
                            className="respHeaderSearch"
                            anchor={'top'}
                            open={state['top']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('top', false)}
                            onOpen={toggleDrawer('top', true)}
                        >
                            <HeaderSearch />
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer mnHdrLftDrwr"
                            anchor={'left'}
                            open={state['left']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('left', false)}
                            onOpen={toggleDrawer('left', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/" className="w-100 text-center">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('left', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('left', false)}>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/" exact>
                                            <span className="material-icons">home</span> Home
                                        </NavLink>
                                    </ListItem>
                                    {!isAuthenticated ? (
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/login">
                                                <span className="material-icons">login</span>
                                                Login
                                            </NavLink>
                                        </ListItem>
                                    ) : (
                                        ''
                                    )}
                                    <hr className="sdBrDvdr" />
                                    <h4 className="sdDvdrHdr">Buyer</h4>
                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/auctions">
                                            <span class="material-icons">manage_search</span>
                                            Auctions
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/search">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="11.72"
                                                height="8.99"
                                                viewBox="0 0 11.72 8.99"
                                            >
                                                <g
                                                    id="Group_3"
                                                    data-name="Group 3"
                                                    transform="translate(-12.269 -12.556)"
                                                >
                                                    <path
                                                        id="Path_1"
                                                        data-name="Path 1"
                                                        d="M2.46,14.546c-.022-.009-.045-.017-.067-.026a.166.166,0,0,1-.122-.173c0-1.338.006-2.675,0-4.012a.342.342,0,0,1,.377-.352c.154.006.309,0,.475,0V9.9q0-1.153,0-2.305A1.226,1.226,0,0,1,3.136,7.4a.262.262,0,0,1,.248-.238,1.216,1.216,0,0,1,.142-.008L6.452,7.15H6.53V7.059q0-.644,0-1.287c0-.159.052-.211.21-.211H8.133l3.518,0a1.257,1.257,0,0,1,.215.02.233.233,0,0,1,.2.208.985.985,0,0,1,.014.164q0,2.343,0,4.686V10.9h1.484c.045,0,.09,0,.134.006a.278.278,0,0,1,.267.231c0,.019.012.037.018.055v3.174a.2.2,0,0,1-.229.171s-.007.006-.011.009ZM6.884,5.914V9.992H8.511c.206,0,.252.045.252.25,0,.162,0,.324,0,.487v.161h2.973V5.912H9.8c0,.035,0,.065,0,.094,0,.2,0,.394,0,.591a.261.261,0,0,1-.283.28c-.13,0-.259,0-.389,0a.272.272,0,0,1-.3-.31c0-.195,0-.389-.005-.584,0-.024,0-.047-.006-.072ZM2.631,14.185H8.41V10.347h-2.4c0,.05,0,.095,0,.139,0,.2,0,.4-.015.6a.208.208,0,0,1-.209.213c-.162.01-.324.015-.486.009a.25.25,0,0,1-.262-.268c0-.055,0-.11,0-.165q0-.265,0-.529h-2.4Zm9.054-2.926c0,.231,0,.452,0,.674a.254.254,0,0,1-.245.286,3.546,3.546,0,0,1-.508-.007.22.22,0,0,1-.208-.2,1.692,1.692,0,0,1-.013-.238c0-.171,0-.342,0-.513H8.784v2.922h4.838V11.259Zm-8.2-1.277H6.516V7.515H5.485c0,.227,0,.449,0,.67a.256.256,0,0,1-.268.292,4.054,4.054,0,0,1-.441,0,.235.235,0,0,1-.255-.254c-.009-.209-.005-.419-.007-.628,0-.026,0-.052,0-.077H3.481ZM9.176,5.91v.611h.269V5.91Zm2.16,5.346h-.264v.618h.264ZM4.87,7.512v.614h.262V7.512Zm.781,2.834H5.392v.615h.259Z"
                                                        transform="translate(10 7)"
                                                        fill="none"
                                                    />
                                                    <path
                                                        id="Path_2"
                                                        data-name="Path 2"
                                                        d="M29.094,7.628H31.02c0,.025.006.049.006.072,0,.194,0,.389.005.584a.272.272,0,0,0,.3.31c.13,0,.259,0,.389,0A.261.261,0,0,0,32,8.313c.005-.2,0-.394,0-.591,0-.03,0-.059,0-.094h1.94V12.6H30.973v-.161c0-.162,0-.324,0-.487,0-.2-.046-.249-.252-.25H29.094Z"
                                                        transform="translate(-12.21 5.285)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_3"
                                                        data-name="Path 3"
                                                        d="M4.376,37.232v-3.84h2.4q0,.265,0,.529c0,.055,0,.11,0,.165a.25.25,0,0,0,.262.268c.162.006.324,0,.486-.009a.208.208,0,0,0,.209-.213c.01-.2.01-.4.015-.6,0-.045,0-.089,0-.139h2.4v3.838Z"
                                                        transform="translate(8.255 -16.047)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_4"
                                                        data-name="Path 4"
                                                        d="M43.036,38.7h1.936v2.921H40.134V38.7h1.929c0,.17,0,.341,0,.513a1.692,1.692,0,0,0,.013.238.22.22,0,0,0,.208.2,3.545,3.545,0,0,0,.508.007.254.254,0,0,0,.245-.286C43.037,39.15,43.036,38.928,43.036,38.7Z"
                                                        transform="translate(-21.35 -20.439)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_5"
                                                        data-name="Path 5"
                                                        d="M9.312,19.407V16.94h1.033c0,.024,0,.05,0,.077,0,.21,0,.419.007.628a.235.235,0,0,0,.255.254,4.055,4.055,0,0,0,.441,0,.256.256,0,0,0,.268-.292c0-.222,0-.443,0-.67h1.032v2.467Z"
                                                        transform="translate(4.169 -2.426)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_6"
                                                        data-name="Path 6"
                                                        d="M42.413,7.614h.269v.611h-.269Z"
                                                        transform="translate(-23.237 5.296)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_7"
                                                        data-name="Path 7"
                                                        d="M53.692,38.687V39.3h-.264v-.618Z"
                                                        transform="translate(-32.357 -20.431)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_8"
                                                        data-name="Path 8"
                                                        d="M17.389,16.923h.262v.614h-.262Z"
                                                        transform="translate(-2.519 -2.412)"
                                                        fill="#fff"
                                                    />
                                                    <path
                                                        id="Path_9"
                                                        data-name="Path 9"
                                                        d="M20.682,33.4v.615h-.259V33.4Z"
                                                        transform="translate(-5.031 -16.05)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </svg>
                                            Products
                                        </NavLink>
                                    </ListItem>
                                    <hr className="sdBrDvdr" />
                                    {user && user.role == 1 ? (
                                        <>
                                            <h4 className="sdDvdrHdr">Seller</h4>

                                            <ListItem button>
                                                <a
                                                    className={
                                                        window.location.pathname.includes(
                                                            '/post-project',
                                                        ) && newUrl.get('type') == 'auction'
                                                            ? 'active'
                                                            : 'inactive'
                                                    }
                                                    onClick={() =>
                                                        handleSellClick('post-project?type=auction')
                                                    }
                                                >
                                                    <span class="material-icons">inventory</span>
                                                    Post Single Lot
                                                </a>
                                            </ListItem>

                                            <ListItem button>
                                                <a
                                                    className={
                                                        window.location.pathname.includes(
                                                            '/post-project',
                                                        ) && newUrl.get('type') == 'buynow'
                                                            ? 'active'
                                                            : 'inactive'
                                                    }
                                                    onClick={() =>
                                                        handleSellClick('post-project?type=buynow')
                                                    }
                                                >
                                                    <span class="material-icons">shopping_bag</span>
                                                    Post Buy Now
                                                </a>
                                            </ListItem>
                                            <hr className="sdBrDvdr" />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <h4 className="sdDvdrHdr">More</h4>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/how_it_works">
                                            <span className="material-icons">groups</span>
                                            How It Works
                                        </NavLink>
                                    </ListItem>
                                    {checkEmursTheme ? null : (
                                        <ListItem button>
                                            <NavLink activeClassName="active" to="/faq">
                                                <span className="material-icons">quiz</span>
                                                FAQs
                                            </NavLink>
                                        </ListItem>
                                    )}
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/termsOfService">
                                            <span className="material-icons">auto_stories</span>
                                            Terms & Conditions
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink activeClassName="active" to="/privacy_policy">
                                            <span className="material-icons">privacy_tip</span>
                                            Privacy Policy
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink activeClassName="active" exact to="/contact_us">
                                            <span className="material-icons">contact_support</span>
                                            Contact Us
                                        </NavLink>
                                    </ListItem>
                                    {/* {user && user.role == 0 && (
                                        <ListItem button>
                                            <NavLink
                                                exact
                                                to="#"
                                                activeClassName="active"
                                                onClick={() => {
                                                    setOpen(true)
                                                }}
                                            >
                                                Sell
                                            </NavLink>
                                        </ListItem>
                                    )} */}
                                    {/* {user && user.role == 1 && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-project"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Post a product
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-auction"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Post an auction
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    exact
                                                    to="/post-auction"
                                                >
                                                    <span class="material-icons">post_add</span>
                                                    Auction Setup
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )} */}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>

                    <React.Fragment>
                        <SwipeableDrawer
                            className="headerDrawer acntRtDrwr"
                            anchor={'right'}
                            open={state['right']}
                            disableBackdropTransition={!iOS}
                            disableDiscovery={iOS}
                            disableSwipeToOpen={false}
                            onClose={toggleDrawer('right', false)}
                            onOpen={toggleDrawer('right', true)}
                        >
                            <div className="headRt respNav d-flex justify-content-start align-items-center">
                                <div className="naLogoHead d-flex justify-content-between align-items-center">
                                    <Link to="/" className="w-100 text-center">
                                        <img
                                            className="headerLogo"
                                            src={global?.storeDetails?.logoValue}
                                            alt={`${global?.storeDetails?.name} logo`}
                                        />{' '}
                                    </Link>
                                    <Button
                                        className="headDrawerClose"
                                        onClick={toggleDrawer('right', false)}
                                    >
                                        <span className="material-icons">clear</span>
                                    </Button>
                                </div>
                                <Divider />
                                <ul className="navRespLinks" onClick={toggleDrawer('right', false)}>
                                    {isAuthenticated && (
                                        <>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">
                                                        dashboard
                                                    </span>
                                                    Dashboard
                                                </NavLink>
                                            </ListItem>
                                            <hr className="sdBrDvdr" />
                                            <h4 className="sdDvdrHdr">Buyer</h4>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/mybids"
                                                >
                                                    <span className="material-icons">gavel</span>
                                                    My Bids
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/favorites"
                                                >
                                                    <span className="material-icons">favorite</span>
                                                    My Favorites
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/savedSearch"
                                                >
                                                    <span className="material-icons">bookmark</span>
                                                    Saved Search
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/preference"
                                                >
                                                    <span className="material-icons">
                                                        notifications
                                                    </span>
                                                    Preferences
                                                </NavLink>
                                            </ListItem>

                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/cards"
                                                >
                                                    <span className="material-icons">
                                                        credit_card
                                                    </span>
                                                    Saved Cards
                                                </NavLink>
                                            </ListItem>
                                            {global?.pluginConfiguration?.ach_stripe?.enable ==
                                            1 ? (
                                                <ListItem button>
                                                    <NavLink
                                                        to="/dashboard/ach"
                                                        activeClassName="active"
                                                    >
                                                        <span className="material-icons">
                                                            account_balance
                                                        </span>
                                                        Saved Bank Details
                                                    </NavLink>
                                                </ListItem>
                                            ) : null}
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/transactions"
                                                >
                                                    <span className="material-icons">receipt</span>
                                                    Transactions
                                                </NavLink>
                                            </ListItem>
                                            {checkBeachThemeClassName ? (
                                                ''
                                            ) : (
                                                <ListItem button>
                                                    <NavLink
                                                        to="/dashboard/message"
                                                        activeClassName="active"
                                                    >
                                                        <span className="material-icons">
                                                            question_answer
                                                        </span>
                                                        Message
                                                    </NavLink>
                                                </ListItem>
                                            )}

                                            {/* {user && user.role != 1 && (
                                                <>
                                                    <hr className="sdBrDvdr mb-0" />
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/becomeSeller?u=1"
                                                        >
                                                            <span className="material-icons">
                                                                paid
                                                            </span>
                                                            Become a Seller
                                                        </NavLink>
                                                    </ListItem>
                                                </>
                                            )} */}

                                            {user && user.role == 1 && (
                                                <>
                                                    <hr className="sdBrDvdr" />
                                                    <h4 className="sdDvdrHdr">Seller</h4>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/sellerdashboard"
                                                        >
                                                            <span className="material-icons">
                                                                store
                                                            </span>
                                                            My Auctions
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/mylots"
                                                        >
                                                            <span className="material-icons">
                                                                store
                                                            </span>
                                                            My Lots
                                                        </NavLink>
                                                    </ListItem>
                                                    <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/dashboard/reports"
                                                        >
                                                            <span className="material-icons">
                                                                description
                                                            </span>
                                                            Reports
                                                        </NavLink>
                                                    </ListItem>

                                                    {/* <ListItem button>
                                                        <NavLink
                                                            activeClassName="active"
                                                            to="/onboard"
                                                        >
                                                            <span className="material-icons">
                                                                payments
                                                            </span>
                                                            Payment Gateway Onboard
                                                        </NavLink>
                                                    </ListItem> */}
                                                </>
                                            )}

                                            <hr className="sdBrDvdr" />
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/dashboard/profile"
                                                >
                                                    <span className="material-icons">person</span>
                                                    My Account
                                                </NavLink>
                                            </ListItem>
                                        </>
                                    )}

                                    <Headernavmenu type="mobile"></Headernavmenu>

                                    {!isAuthenticated ? (
                                        <>
                                            <ListItem button>
                                                <NavLink activeClassName="active" to="/login">
                                                    <span className="material-icons">login</span>
                                                    Login
                                                </NavLink>
                                            </ListItem>
                                            <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/how_it_works"
                                                >
                                                    <span className="material-icons">groups</span>
                                                    How It Works
                                                </NavLink>
                                            </ListItem>
                                            {/* <ListItem button>
                                                <NavLink activeClassName="active" to="/sign-up">
                                                    <span className="material-icons">
                                                        how_to_reg
                                                    </span>
                                                    Register
                                                </NavLink>
                                            </ListItem> */}
                                        </>
                                    ) : (
                                        <>
                                            {/* <ListItem button>
                                                <NavLink
                                                    activeClassName="active"
                                                    to="/becomeSeller?u=1"
                                                >
                                                    <span className="material-icons">paid</span>
                                                    Become a Seller
                                                </NavLink>
                                            </ListItem> */}
                                            <ListItem button onClick={logoutUser}>
                                                <span className="material-icons">
                                                    power_settings_new
                                                </span>
                                                Logout
                                            </ListItem>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </SwipeableDrawer>
                    </React.Fragment>
                    {isAuthenticated && user && user.role != 1 ? (
                        <>
                            {!cards?.length ? (
                                <div className="nocardBanner">
                                    <p
                                        className="m-0"
                                        onClick={() =>
                                            handleRedirectInternal(history, 'dashboard/cards')
                                        }
                                    >
                                        To place a bid or purchase an item, you must have a payment
                                        method connected
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    ) : isAuthenticated && user && user.role == 1 ? (
                        <>
                            {global.storeConfigration?.stripe_integration?.value == 1 &&
                            user?.card_profileid == '' ? (
                                <div className="nocardBanner">
                                    <p
                                        className="m-0"
                                        onClick={() =>
                                            handleRedirectInternal(history, 'dashboard/profile')
                                        }
                                    >
                                        Please click here to finalize your seller account setup.
                                    </p>
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </>
            )}
            {isDesktop && (
                <React.Fragment>
                    <SwipeableDrawer
                        className="catgDrawer auctions"
                        anchor={'left'}
                        open={state['left']}
                        disableBackdropTransition={!iOS}
                        disableDiscovery={iOS}
                        disableSwipeToOpen={false}
                        onClose={toggleDrawer('left', false)}
                        onOpen={toggleDrawer('left', true)}
                    >
                        <div className="catgHead">
                            <h3>Auctions</h3>
                            <Button onClick={toggleDrawer('left', false)}>
                                <span className="material-icons">chevron_left</span>
                            </Button>
                        </div>
                        {viewAuction.length ? (
                            <>
                                <ul className="catgLinks" onClick={toggleDrawer('left', false)}>
                                    {viewAuction.map((data, index) => (
                                        <ListItem
                                            button
                                            className="homeCatgInner auctions"
                                            key={index}
                                            onClick={() => handleClick(data.id)}
                                        >
                                            <div className="auctionListInner">
                                                <h5>{data.title}</h5>
                                                {/* <p>Total lots: {data.lotCount}</p> */}
                                            </div>
                                            <Timer
                                                date_added={data.date_added}
                                                date_closed={data.date_closed}
                                                withText={1}
                                                endText={'Time Left'}
                                                startText="Live Auction Starts In"
                                                theme="multiseller"
                                            />
                                        </ListItem>
                                    ))}
                                </ul>
                                <div className="px-3">
                                    <PrimaryButton
                                        className="viewAllAuc"
                                        onClick={() => handleClose(history, 'auctions')}
                                        label="View all auctions"
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="noCatg">
                                <img src="/assets/svg/noProduct.svg" alt="No Categories" />
                                <h6>No Auctions Found</h6>
                            </div>
                        )}
                    </SwipeableDrawer>
                </React.Fragment>
            )}
            {console.log(global?.storeDetails?.text, 'global?.storeDetails?.text')}
            {global?.storeDetails?.text?.length ? (
                <div className="textSliderWrapper py-2">
                    <Swiper
                        navigation={false}
                        spaceBetween={13}
                        grabCursor={true}
                        slidesPerView={1}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        pagination={false}
                        modules={[Autoplay, EffectCoverflow, Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {global?.storeDetails?.text?.map((val) => (
                            <SwiperSlide>
                                <div>
                                    <p className="mb-0">{val}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : null}

            {userCount.itemunPaidCount ? (
                <div className="auctionHasItems noPrint">
                    <img src="/assets/svg/TrophyIcon.svg" />
                    <h3>
                        Congratulations! You have won an auction.{' '}
                        <Link to={`/dashboard/mybids?s=1`}>Click here</Link> to make the payment.
                    </h3>
                </div>
            ) : null}
            <CustomDialog title={'Become A Seller'} open={open} function={toggleOpen}>
                <BecomeSeller open={open} setOpen={setOpen} />
            </CustomDialog>
            <Dialog
                className="slctLctnPopup"
                open={locationDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleLocationDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="lctnDialogWrapper">
                    <div className="imgWrpr">
                        <img src="/assets/images/locIcon.png" />
                    </div>
                    <div className="rtInfoWrpr">
                        <h3>Shopping Location</h3>
                        <label>You are currently shopping in</label>
                        <p className="crntLctn">
                            <span className="material-icons">location_on</span>
                            {productLocalState.globalLocation}
                        </p>
                        {productLocalState.globalLocations?.length > 1 ? (
                            <>
                                <h4>Other Location</h4>
                                {productLocalState.globalLocations.map(
                                    (val) =>
                                        productLocalState.globalLocation !== val && (
                                            <Button
                                                className="othLctn"
                                                onClick={() => handleLocationChange(val)}
                                            >
                                                {val}
                                            </Button>
                                        ),
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </Dialog>
            <Dialog
                className=""
                open={termsDialog}
                TransitionComponent={Transition}
                onClose={handleTermsDialog}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disableBackdropClick
            >
                <div className="lctnDialogWrapper termwrap">
                    <div className="rtInfoWrpr termswrapper">
                        <h3>Terms & Conditions</h3>
                        <hr />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: global.storeDetails.terms_condition,
                            }}
                        ></p>
                        <div className="d-flex align-items-center align-items-mob-start">
                            <input
                                type="checkbox"
                                checked={termsCheck}
                                onClick={() => setTermsCheck(!termsCheck)}
                            />
                            <h4>
                                <a href="#">
                                    I Agree To These Terms, Conditions And Bidding Rules.
                                </a>
                            </h4>
                        </div>
                        <PrimaryButton
                            label="Accept and Continue"
                            onClick={() => {
                                termsCheckUpdate()
                                setBtnLoading(true)
                            }}
                            disabled={!termsCheck || btnLoading}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default React.memo(Header)
